@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap);
/* Custom CSS ------------------- */
body{
	font-family: 'Open Sans', sans-serif;
}
.fnt-300{
	font-weight: 300;
}
.fnt-400{
	font-weight: 400;
}
.fnt-600{
	font-weight: 600;
}
.fnt-700{
	font-weight: 700;
}
.fnt-800{
	font-weight: 800;
}

.logo-mh{
	display: inline-block;
}

/* Main content -------------- */
.main-container-content{
	min-height: calc(100vh - 50px);
	background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 150px;
    padding-bottom: 150px;
    position: relative;
    color: #fff;
}
.overlay{
	background-color: rgba(3, 38, 51, 0.7);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.box-white{
	background-color: #fff;
	color: #000;
	display: flex;
	flex-direction: column;
	height: 100%;
}
.box-white .lead-small-ttle{
	display: flex;
	min-height: 42px;
	flex-direction: column;
}
.lead-desc{
	font-size: 21px;
}
.table td{
	border-top: none;
}
.q-star .fa-star{
	color: #0086CE;
}
.lead-small-ttle{
	font-size: 1.1rem;
    font-weight: 500;
    color: #666;
}

/* Section 2 --------------------- */
.section-2{
	padding-top: 100px;
	padding-bottom: 100px;
}
.how-icon{
	max-height: 135px;
}

/* Section 3 --------------------- */
.section-3{
	padding-top: 100px;
	padding-bottom: 100px;
	background-color: #fafafa;
}
.card-overflow-cus{
	height: 350px;
    overflow-y: auto;
}
.img-thumbnail-cust{
	object-fit: contain;
	width: 65px;
	height: 65px;
}

/* Section 4 --------------------- */
.section-4{
	padding-top: 100px;
	padding-bottom: 100px;
	background-color: #F6F1F1;
}

/* carousel css here ------------------- */

.wrap-marketplace-box-item {
    background-color: #fff;
    border-radius: 2px;
    display: block;
    padding: 15px;
    text-align: center;
    word-wrap: break-word;
    color: #000;
    text-shadow: none;
    margin-bottom: 25px;
}
/* Logo Found */
.wmbi-img-logo {
    display: block;
    margin-bottom: 15px;
}
.wmbi-img-logo img {
	display: inline-block !important;
	max-height: 60px;
	width: auto !important;
}
/* Title Brand */
.wmbi-ttle {
    background-color: #f5f5f5;
    color: #2e9f3e;
    font-size: 1.3em;
    font-weight: bold;
    padding: 10px 5px;
}
.marg-m-ttlTop {
    margin-top: 0;
}
.p-marg-btm {
    margin-bottom: 0;
}
.ul-wmbi-zero li {
    padding: 0;
}
.ul-wmbi-zero li .btn{
    margin: 0;
}

/* Carousel Custom Brands --------------- */
#brandsCarousel .owl-item{
    padding:15px;
}
#brandsCarousel .owl-prev::before,#brandsCarousel .owl-next::before{
    background-color: #e1e1e1;
}
 /* ===================================
    Owl carousel
====================================== */
.main-content-owlcarousel {
  position: relative;
}
.main-content-owlcarousel .owl-theme .owl-nav {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
}
.main-content-owlcarousel .owl-theme .owl-nav .owl-prev, 
.main-content-owlcarousel .owl-theme .owl-nav .owl-next {
  position: absolute;
  color: inherit;
  background: none;
  border: none;
  z-index: 100;
}
.main-content-owlcarousel .owl-theme .owl-nav .owl-prev i, 
.main-content-owlcarousel .owl-theme .owl-nav .owl-next i {
  font-size: 2.5rem;
  color: #cecece;
}
.main-content-owlcarousel .owl-theme .owl-nav .owl-prev {
  left: 0;
}
.main-content-owlcarousel .owl-theme .owl-nav .owl-next {
  right: 0;
}
.main-content-owlcarousel .owl-theme .owl-nav .owl-prev,
.main-content-owlcarousel .owl-theme .owl-nav .owl-next{
	font-size: 2rem;
	padding: 0 1rem !important;
	opacity: 0;
	background-color: #869791;
	color: #fff;
}
.main-content-owlcarousel:hover .owl-theme .owl-nav .owl-prev,
.main-content-owlcarousel:hover .owl-theme .owl-nav .owl-next{
	opacity: 1;
}

/* Buy Page Form ------------- */
.wrap-box-main,
.bg-left-box,
.bg-right-box{
	position: relative;
	width: 100%;
}
.wrap-box-main{
	display: flex;
	flex-wrap: wrap;
}
.bg-left-box,
.bg-right-box{
	padding: 3rem;
}
/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
	.bg-left-box,
	.bg-right-box{
		flex: 0 0 50%;
		max-width: 50%;
	}
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.bg-left-box,
	.bg-right-box{
		flex: 0 0 50%;
		max-width: 50%;
	}
}
.bg-left-box{
	background-color: #eaeaea;
}
.bg-right-box{
	background-color: #fafafa;
	color: #777;
	flex-direction: column;
}
.flex-d-col{
	flex-direction: column;
}

.preloader-loading{
	position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url(https://cdn.vnoc.com/icons/loading-128x128.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
}

/* 404 CSS STYLE --------------------------- */
#notfound{
    position:relative;
    height:100vh
}
#notfound .notfound{
    position:absolute;
    left:50%;
    top:50%;
    -webkit-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%)
}
.notfound{
    max-width:560px;
    width:100%;
    padding-left:160px;
    line-height:1.1
}
.notfound .notfound-404{
    position:absolute;
    left:0;
    top:0;
    display:inline-block;
    width:140px;
    height:140px;
    background-image:url('https://cdn.vnoc.com/icons/contrib/emoji.png');
    background-size:cover
}
.notfound .notfound-404:before{
    content:'';
    position:absolute;
    width:100%;
    height:100%;
    -webkit-transform:scale(2.4);
    transform:scale(2.4);
    border-radius:50%;
    background-color:#f2f5f8;
    z-index:-1
}
.notfound h1{
    font-family:nunito,sans-serif;
    font-size:65px;
    font-weight:700;
    margin-top:0;
    margin-bottom:10px;
    color:#151723;
    text-transform:uppercase
}
.notfound h2{
    font-family:nunito,sans-serif;
    font-size:21px;
    font-weight:400;
    margin:0;
    text-transform:uppercase;
    color:#151723
}
.notfound p{
    font-family:nunito,sans-serif;
    color:#999fa5;
    font-weight:400
}
@media only screen and (max-width:767px){
    .notfound .notfound-404{
        width:110px;
        height:110px
    }
    .notfound{
        padding-left:15px;
        padding-right:15px;
        padding-top:110px
    }
}

/* Dark Footer ------------------ */
.footer-dark-1,.footer-dark-2{
	line-height: 20px;
}
.footer-dark-1 .text-g1,.footer-dark-2 .text-g1{
	color: #ccc;
}
.footer-dark-1 .f-a-links a,.footer-dark-2 .f-a-links a{
	color: #ccc;
}
.footer-dark-1 .f-a-links a:hover, .footer-dark-2 .f-a-links a:hover{
	color: #e1e1e1;
	text-decoration: none;
}
.footer-dark-1{
	color: #fff;
	padding: 60px 0 50px;
	background-color: #333;
}
.footer-dark-1 h3{
	margin-top: 0;
	word-break: break-word;
    font-size: 1.3rem;
}
.fnt-bold{
	font-weight: bold;
}
.footer-dark-2{
	color: #fff;
	padding: 25px 0;
	background-color: #222;
}
.footer-dark-2 ul.list-inline{
	margin-bottom: 0;
}
.socials-ul li{
	padding-right: 0;
	padding-left: 0;
}
/* Black B */
.footer-dark-1.footer-dark-b-1{
	background-color: #020202;
}
.footer-dark-2.footer-dark-b-2{
	background-color: #0e0e0e;
}


/* For Social Media Style Brand Details */
/* Wrapper */
.icon-button {
	border-radius: 0.6rem;
	cursor: pointer;
	display: inline-block;
	font-size: 2.0rem;
	height: 3rem;
	line-height: 3rem;
	position: relative;
	text-align: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 3rem;
}

/* Circle */
.icon-button span {
	border-radius: 0;
	display: block;
	height: 0;
	left: 50%;
	margin: 0;
	position: absolute;
	top: 50%;
	transition: all 0.3s;
	width: 0;
}
.icon-button span {
	width: 3rem;
	height: 3rem;
	border-radius: 0.6rem;
	margin: -1.5rem;
}
.twitter span {
	background-color: #4099ff;
}
.facebook span {
	background-color: #3B5998;
}
.google-plus span {
	background-color: #dd4b39;
}
.youtube span {
	background-color: #bb0000;
}
.pinterest span {
	background-color: #cb2027;
}
.angellist span {
	background-color: #000;
}
.github span {
	background-color: #000;
}
.linkedin span {
	background-color: #007bb6 ;
}
.tumblr span {
	background-color: #36465d ;
}
.foursquare span {
	background-color: #0072b1 ;
}

/* Icons */
.icon-button i {
	background: none;
	color: white;
	height: 3rem;
	left: 0;
	line-height: 3rem;
	position: absolute;
	top: 0;
	width: 3rem;
	z-index: 10;
}
/* For Image iCons */
.social-img-icon-a{
	border-radius: 0.6rem;
	display: block;
	height: 30px;
	overflow: hidden;
	width: 30px;
}
.social-img-icon-a img{
	height: 30px;
}

/* Public Pages ------------------------------ */
.public-pages{
	background-color: #fff;
	min-height: 100vh;
	padding-top: 50px;
	padding-bottom: 50px;
}

/* App Page -------------------- */
.box-app{
	display: flex;
	height: 100%;
	flex-direction: column;
	border-radius: 2px;
}
.box-app:hover {
    background: #F7F9FC;
    box-shadow: 0px 0px 3px #000;
}
.desc-app{
	display: flex;
	height: 100%;
}
.img-app-logo img{
	object-fit: contain;
	max-height: 100%;
}


/* Nav Tabs - Buy Form Page - Our Process ------------------------- */
.circle-step-main {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
}
.circle-step-main span {
    display: block;
}

.nav.nav-tabs.process-nav-tab{
	display: block;
	border-bottom: none;
	position: relative;
	text-align: center;
	margin-bottom: 1rem;
}
.nav.nav-tabs.process-nav-tab:before{
	content: "";
    display: block;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #ddd;
    position: absolute;
    top: 50%;
}
.nav.nav-tabs.process-nav-tab .nav-item{
	display: inline-block;
	position: relative;
	z-index: 2;
	margin-right: 15px;
}

.nav.nav-tabs.process-nav-tab .nav-item a{
	display: block;
	border-radius: 100%;
    box-shadow: 0 0 30px 0 transparent;
    border: none;
    color: #c7c7c7;
    height: 100px;
    width: 100px;
    background-color: #f5f5f5;
}

.nav.nav-tabs.process-nav-tab .nav-item a.active{
	border: none;
    background-color: #fff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    color: #000;
    text-align: center;
}
